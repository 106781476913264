import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { LANG } from "../constants/constants";
import { setLang } from "../reducers/systemSlice";
import { useLocation } from 'react-router-dom';

export const LanguageWrapper = ({children}) => {

  const params = useParams();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.system.lang);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // if (params.language) {
      switch (params.language?.toUpperCase()) {
        case LANG.TC:
        case LANG.EN:
        case LANG.SC:
          dispatch(setLang(params.language?.toUpperCase()));
          break;
        case "ZH-HK":
        case "ZH-TW":
          history.replace("/" + LANG.TC + location.pathname.replace(/^\/[^/]+/, '') + location.search);
          break;
        case "ZH-CN":
          history.replace("/" + LANG.SC + location.pathname.replace(/^\/[^/]+/, '') + location.search);
          break;
        default:
          history.replace("/" + LANG.EN + location.pathname.replace(/^\/[^/]+/, '') + location.search);
          break;
      }
    // } else {
    //   dispatch(setLang(LANG.EN));
    // }

  }, [location, params.language]);

  return (
    <>
    {
    lang &&
    children}
    </>
  )
}